import React, { useState } from 'react'
import classNames from 'classnames'
import Container from 'src/components/container'
import Section from 'src/components/section'
import Picture from 'src/components/picture'
import Button from 'src/components/button'
import RichText from 'src/components/rich-text'
import expandBtn from 'src/assets/expand-btn.svg'
import retractBtn from 'src/assets/retract-btn.svg'

const AccordionsSection = ({ className, title, subtitle, accordions = [] }) => {
  const [currentId, setCurrentId] = useState(null)

  return (
    <Section className={classNames('accordions-section', className)}>
      <Container>
        {title && <h3 className="section-title centered">{title}</h3>}
        {subtitle && <p className="section-subtitle centered">{subtitle}</p>}
        <div className="accordions">
          {accordions.map(({ id, title, description }) => (
            <div
              className={classNames(
                'accordion',
                currentId === id && 'expanded'
              )}
              key={id}
            >
              <Button
                className="title-container"
                onClick={() => setCurrentId(id)}
              >
                <h2>{title}</h2>
                <Picture
                  className="expand-btn"
                  image={expandBtn}
                  fitWidth
                  contain
                />
              </Button>
              <div className="body-container">
                <div className="details">
                  <h2>{title}</h2>
                  <RichText className="body">{description}</RichText>
                </div>
                <Button
                  className="retract-btn"
                  onClick={() => setCurrentId(null)}
                >
                  <Picture fitWidth contain image={retractBtn} />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </Section>
  )
}

export default AccordionsSection
