import React from 'react'
import classNames from 'classnames'

const Container = ({ className, children, style = null, maxWidth = true }) => {
  return (
    <div
      style={style}
      className={classNames(
        'container',
        maxWidth && 'has-max-width',
        className
      )}
    >
      {children}
    </div>
  )
}

export default Container
