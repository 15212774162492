import React, { useRef, useState, useLayoutEffect } from 'react'
import classNames from 'classnames'
import { useStore } from 'src/state'
import Container from 'src/components/container'
import Section from 'src/components/section'
import Link from 'src/components/link'
import Picture from 'src/components/picture'
import Button from 'src/components/button'
import RichText from 'src/components/rich-text'
import chevron from 'src/assets/chevron.svg'

const HeroSection = ({
  className,
  title,
  backgroundImage,
  portraitBackgroundImage,
  backgroundVideoUrl,
  subtitleText,
  subtitleUrl,
  formId,
  email,
  description,
  openSubtitleInNewTab,
  shouldShowChevron = true,
}) => {
  const heroSectionRef = useRef()
  const {
    isTransitioning,
    isBrowser,
    windowHeight,
    isScrolled,
    windowWidth,
    setFormOverlayId,
    showFormOverlay,
  } = useStore()
  const [height, setHeight] = useState(0)
  const isFixed = height <= windowHeight && windowWidth > 768

  useLayoutEffect(() => {
    const onResize = () => {
      if (isBrowser && heroSectionRef.current)
        setHeight(heroSectionRef.current.offsetHeight)
    }
    if (isBrowser) {
      window.addEventListener('resize', onResize)
      onResize()
    }
    return () => {
      if (isBrowser) window.removeEventListener('resize', onResize)
    }
  }, [isBrowser])

  return (
    <>
      <Section
        ref={heroSectionRef}
        className={classNames(
          'hero-section',
          isTransitioning && 'is-transitioning',
          isFixed && 'is-fixed',
          isScrolled && 'is-scrolled',
          className
        )}
        backgroundImage={backgroundImage}
        portraitBackgroundImage={portraitBackgroundImage}
        backgroundVideoUrl={backgroundVideoUrl}
      >
        <Container>
          <div className="title">
            <div className={classNames('h1s', isScrolled && 'is-scrolled')}>
              <h1>{title}</h1>
              <h1 className="bg-title">{title}</h1>
            </div>
            {description && (
              <div
                className={classNames(
                  'description',
                  isScrolled && 'is-scrolled'
                )}
              >
                <RichText>{description}</RichText>
              </div>
            )}
          </div>
        </Container>
        {subtitleText && (
          <div className="subtitle">
            {email ? (
              <Link to={`mailto:${email}`}>{subtitleText}</Link>
            ) : subtitleUrl ? (
              <Link
                className="subtitle-link"
                to={subtitleUrl}
                newTab={openSubtitleInNewTab}
              >
                {subtitleText}
              </Link>
            ) : formId ? (
              <Button
                onClick={() => {
                  setFormOverlayId(formId)
                  showFormOverlay()
                }}
              >
                <div className="action-text">{subtitleText}</div>
              </Button>
            ) : (
              <div className="subtitle-text">{subtitleText}</div>
            )}
          </div>
        )}
        {shouldShowChevron && (
          <div
            className={classNames(
              'hero-section-chevron',
              isScrolled && 'is-scrolled'
            )}
          >
            <Picture image={chevron} />
          </div>
        )}
      </Section>
      <section
        className={classNames(
          'hero-section-shim',
          isFixed && 'is-fixed',
          className
        )}
        style={{ height: `${height}px` }}
      />
    </>
  )
}

export default HeroSection
