import React from 'react'
import classNames from 'classnames'
import Section from 'src/components/section'
import Container from 'src/components/container'
import Picture from 'src/components/picture'
import Slider from 'src/components/slider'
import BoxButton from 'src/components/box-button'
import Link from 'src/components/link'
import AspectBox from 'src/components/aspect-box'

const FeaturedProductSection = ({
  className,
  eyebrowText,
  title,
  description,
  buttonText,
  buttonUrl,
  images = [],
}) => {
  return (
    <Section className={classNames('featured-product-section', className)}>
      <Container>
        <div className="product-content">
          <div className={classNames('images', `count-${images.length}`)}>
            <Slider dots autoplay slidesToShow={1} arrows>
              {images.map((image) => (
                <AspectBox
                  key={image.id}
                  className="slider-image"
                  x={600}
                  y={600}
                >
                  <Picture
                    defaultWidth={1200}
                    image={image}
                    fitWidth
                    fitHeight
                    cover
                  />
                </AspectBox>
              ))}
            </Slider>
          </div>
          <div className="copy">
            <div className="eyebrow-text">
              <div className="left-eyebrow" />
              <div className="text">{eyebrowText}</div>
              <div className="right-eyebrow" />
            </div>
            <h3 className="size-big">{title}</h3>
            <p>{description}</p>
            <Link to={buttonUrl}>
              <BoxButton>{buttonText}</BoxButton>
            </Link>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default FeaturedProductSection
