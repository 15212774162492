import React from 'react'
import classNames from 'classnames'
import RichText from 'src/components/rich-text'

const Ticker = ({
  children,
  className,
  childCount = 5,
  speed = 20,
  style = null,
  direction = 'left',
}) => {
  return (
    <div style={style} className={classNames('ticker', className)}>
      <div className="ticker-children">
        {Array(childCount)
          .fill(null)
          .map((n, i) => (
            <div key={i} className="ticker-child">
              <RichText>{children}</RichText>
            </div>
          ))}
      </div>
    </div>
  )
}

export default Ticker
