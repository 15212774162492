import React from 'react'
import { useStore } from 'src/state'
import Page from 'src/components/page'
import Section from 'src/components/section'
import Ticker from 'src/components/ticker'
import HeroSection from 'src/components/hero-section'
import AccordionsSection from 'src/components/accordions-section'
import FeaturedProductSection from 'src/components/featured-product-section'

const PageHome = ({ pageContext: { collection } }) => {
  const {
    homePage: { hero, ticker, featuredProduct, faqs },
  } = useStore()
  return (
    <Page className="page-home">
      <HeroSection {...hero} />
      {ticker.visible && (
        <Section>
          <Ticker>{ticker.svg}</Ticker>
        </Section>
      )}
      {featuredProduct.visible && (
        <FeaturedProductSection {...featuredProduct} />
      )}
      {faqs.visible && (
        <AccordionsSection title={faqs.title} accordions={faqs.questions} />
      )}
    </Page>
  )
}

export default PageHome
